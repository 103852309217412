.intro
	.fix-height
		height: 100vh !important
		min-height: 700px !important
	.frase
		transform: none !important
		@media only screen and (max-width: $md)
			width: 280px
		@media only screen and (max-width: $sm)
			width: 240px

.bienvenido
	.logo
		@media only screen and (max-width: $xl)
			@include fluid('width', 100, 230)

.box-vinos
	.center
		position: relative
		top: -20px

	.imagen_vino, .fix-img, .center-fix
		transition: all 1.5s ease
		transform: scale(1)
	&:hover
		.imagen_vino, .fix-img, .center-fix
			transform: scale(1.1)
			transition: all 1.5s ease
	.fix-img
		height: 650px
		width: 100%
		@media only screen and (max-width: $xl)
			height: 620px
		
	.box-absolute
		.button_3
			position: absolute
			bottom: 30px

	.h2
		.oro
			@include fluid('font-size', 40, 92)
			line-height: 0.3
	.grilla-100
		@media only screen and (max-width: $md)
			max-width: 580px
			margin: 0 auto

	.h3
		@include fluid('font-size', 34, 38)
		line-height: 1
		padding: 10px 20px 0px
		@media only screen and (max-width: $md)
			text-align: center !important

.section-requingua
	.h3
		@media only screen and (max-width: $md)
			text-align: center !important
	.button
		@media only screen and (max-width: $md)
			display: block
			margin: 0 auto
			width: 110px	

	.logo-requingua
		@media only screen and (max-width: $md)
			@include fluid('width', 200, 540)

			



.section-siguenos

	.social__icon
		margin-right: 20px
		&::before
			font-size: 26px



