
.single-vinos
	.header_fixed
		border-bottom: 1px solid var(--color-oro) !important
	.section-masthead_fixed
		height: 0
		@media only screen and (max-width: $md)
			display: none

		.box-vinos
			position: relative
			top: 120px
			.fix-img
				height: 540px
	#page-header.consistencia
		.logo__wrapper-img
			.cls-1
				fill: #141414 !important
		.menu > li a
			color: #141414 !important	
		.header__burger-line:before
			background: #141414 !important	
		&.opened
			.header__burger-line:before
				background: #fff !important	

			
		
	#page-header.consistencia.header_sticky
		.logo__wrapper-img
			.cls-1
				fill: #fff !important
		.menu > li a
			color: #fff !important		
		.header__burger-line:before
			background: #fff !important	

	.header__burger-line:before
		background: #fff

/* FIX ALTURA BANNER * /

.banner-vino
	height: 100vh
	min-height: 900px
	@media (max-height: 750px) and (min-width: 992px)
		//height: 850px !important
	@media only screen and (max-width: $md)
		//height: 800px !important	
	.pt-fix
		padding-top: 120px
	.medallas
		margin-top: 60px
		.lazy-wrapper
			margin-bottom: 15px
	.medallas-movil
		.lazy-wrapper
			margin-bottom: 15px
	.volver
		position: absolute
		bottom: 30px
		left: 30px
		a
			text-transform: uppercase
			font-weight: bold
			color: var(--h1-color-dark)
			letter-spacing: 1px
			font-size: 15px

.page-wrapper__content
	&.excelencia, &.caracter
		.banner-vino
			h1, h4, p
				color: #fff !important
		.volver
			a
				color: #fff !important
		.section-masthead_fixed
			.box-vinos
				h2
					color: #fff !important
				h6
					color: var(--color-oro) !important
		@media only screen and (max-width: $md)			
			.info-vino
				padding-bottom: 0px
				background: var(--color-dark-1)	
				p, .h3
					color: #fff !important



.box-recetas
	position: absolute
	top: 0px
	width: 100%
	height: 100%
	h3
		padding-right: 20px
		font-size: 32px
	.icon-receta
		@include fluid('width', 80, 120)

.firma-enologo
	@media only screen and (max-width: $xs)
		max-width: 160px

.section-carousel
	.swiper-slide
		width: 400px
		@media only screen and (max-width: $xs)
			width: 340px
			margin-right: 20px !important

		&.consistencia
			.button__label
				color: var(--h1-color-dark)
				border-bottom: 2px solid var(--h1-color-dark)
		&.excelencia, &.caracter
			.h3
				color: #fff !important
			.button__label
				color: #fff !important
				border-bottom: 2px solid #fff !important
			h6
				color: var(--color-oro) !important
				
	.slider__arrow
		color: #fff !important
		font-size: 34px

.box-md
	@media only screen and (max-width: $md)
		max-width: 767px !important

.box-sm
	@media only screen and (max-width: $sm)
		max-width: 576px !important
		max-height: 600px !important

.medallas-movil
	position: absolute
	top: 0px
	padding-left: 10px
	@include fluid('width', 70, 150)

.recetas
	.mask-reveal__layer
		background: #000
		img
			opacity: 0.5
			height: 520px !important
			@media only screen and (max-width: $lg)
				height: 500px !important
			@media only screen and (max-width: $sm)
				height: 480px !important


.notas-cata
	padding: 30px 0px
	.section__headline
		width: 100%
		background: var(--color-oro) !important
	.nota
		margin-bottom: 10px
		max-width: 460px
		.lazy-wrapper
			max-width: 28px !important
			margin-right: 10px
		p
			margin: 0px
			font-size: 15px
			line-height: 1.2
			font-weight: bold
			@media only screen and (max-width: $sm)
				font-weight: normal
.meridaje
	.lazy-wrapper
		max-width: 380px



	

