
.banner-vinos
	height: 360px
	@media only screen and (max-width: $md)
		height: 300px
	@media only screen and (max-width: $xs)
		height: 250px

.grid_fluid-4
	@media only screen and (max-width: $md)
		margin: -2vw !important

.cat-vinos
	position: relative
	padding-bottom: 30px
	@media only screen and (max-width: $sm)
		padding-bottom: 0px
	h6
		color: #555
	img
		@media only screen and (max-width: $xl)
			max-width: 240px
		@media only screen and (max-width: $lg)
			max-width: 220px
		@media only screen and (max-width: $md)
			max-width: 200px
	h6
		@media only screen and (max-width: $sm)
			margin-bottom: 30px

	.section__headline
		@media only screen and (max-width: $sm)
			display: none
		&.full
			margin-top: 20px
			width: calc( 100% + 70px)
			background: rgba(#555555, 0.4)
			height: 2px
			left: -35px
			position: relative
		&.active
			background: var(--color-oro)
			height: 2px
			width: calc( 33% + 0px)
			left: -15px
			position: relative
			margin-top: 0px !important
			position: absolute
			bottom: 30px

	.underline-hover:hover
		h6
			color: #fff !important

.term-caracter .cat-vinos .caracter, .term-excelencia .cat-vinos .excelencia, .term-consistencia .cat-vinos .consistencia
	a
		pointer-events: none
	h6
		color: #fff

.term-caracter
	.section__headline
		&.active
			background: var(--color-oro)
			height: 2px
			width: calc( 33% + 20px)
			left: 0
			right: 0
			margin: 0 auto

.term-excelencia
	.section__headline
		&.active
			background: var(--color-oro)
			height: 2px
			width: calc( 33% + 0px)
			right: -15px
			left: auto

.term-caracter, .term-excelencia
	.filtro
		display: none !important
	.grid
		margin-top: 20px
	.box-vinos
		h6
			color: var(--color-oro) !important
		h2
			color: #fff !important
		.button__label
			color: #fff !important
			border-bottom: 2px solid #fff !important



	
.tax-categorias
	.box-vinos
		.button__label
			color: #141414
			border-bottom: 2px solid #141414

.filtro
	.filter__inner
		max-width: 640px
		margin: 0 auto
		.filter__item-label
			color: #fff
			text-transform: uppercase
			letter-spacing: 2px
			font-size: 16px

.filter__wrapper-dropdown
	.js-filter__select
		border: 1px solid var(--color-oro2)
		border-radius: 0
		font-weight: bold
	select
		color: #fff !important


.tax-categorias
	.box-vinos
		.fix-img
			height: 620px	


