.button
	position: relative
	display: inline-block
	font-size: 14px
	line-height: 1
	font-weight: 500
	white-space: nowrap
	cursor: pointer
	box-shadow: none
	border-width: 2px
	border-style: solid
	padding: 11px 18px 8px
	color: $color-gray-1
	@media only screen and (max-width: $xs)	
		font-size: 13px
	+trans1
	transition-property: color, background-color, border-color
	&:focus
		outline: none
	&:hover
		.button__label-normal
			.button__title
				transform: translate(0%, -100%)
				opacity: 0
			.button__icon
				opacity: 0
				transform: translate(100%, 0%)
		.button__label-hover
			.button__title
				transform: translate(0%, 0%)
				opacity: 1
			.button__icon
				opacity: 1
				transform: translate(0%, 0%)

.button_icon
	display: inline-flex
	border: none
	.button__label
		padding: 0
		letter-spacing: 1px
		text-transform: uppercase
		font-weight: normal

.button_2
	border: none
	padding: 0px
	&.white
		.button__label
			color:  #fff !important
			border-bottom: 1px solid #fff !important
			font-size: 18px
			@media only screen and (max-width: $lg)
				font-size: 17px
			@media only screen and (max-width: $sm)
				font-size: 16px	


	.button__label
		color: var(--color-oro) !important
		letter-spacing:2px
		text-transform: uppercase
		font-weight: bold
		border-bottom: 1px solid var(--color-oro)
		padding: 0px 0px 4px 0px
		font-size: 15px
		@media only screen and (max-width: $xs)	
			font-size: 14px
.button_3
	border: none
	padding: 0px
	.button__label
		letter-spacing:1px
		text-transform: uppercase
		font-weight: bold
		border-bottom: 2px solid #fff
		padding: 0px 0px 4px 0px

		

.button__label-hover
	position: absolute
	top: 50%
	left: 50%
	transform: translate(-50%, -50%)
	.button__title
		transform: translate(0%, 100%)
		opacity: 0
	.button__icon
		transform: translate(-100%, 0%)
		opacity: 0

.button__label-normal
	.button__title
		transform: translate(0%, 0%)
		opacity: 1

.button__title
	display: inline-block
	transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out
.button__label
	display: block
	padding: 18px 42px

.button__icon
	display: inline-flex
	align-items: center
	justify-content: center
	// width: 60px
	transition: transform 0.2s ease-in-out, opacity 0.2s ease-in-out
	font-size: 75%
.button__icon_before
	margin-right: 0.5em
.button__icon_after
	margin-left: 0.25em

.button_fullwidth
	width: 100%
.button-fullwidth
	.button
		width: 100%


