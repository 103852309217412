.gris-parrafo
	@media only screen and (max-width: $md)
		max-width: 	600px
		margin: auto
		

.benoit
	@media only screen and (max-width: $md)
		max-height: 400px

.detras-vino
	.text-detras
		@media only screen and (max-width: $md)
			text-align: center !important


#requingua
	.section__bg
		opacity: 0.7


.home
	.gris-parrafo
		@include fluid('font-size', 28, 36) 
			
.gris-parrafo
	a 
		color: var(---gris-parrafo) !important
		text-decoration: underline
