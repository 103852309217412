.modal-open
	overflow: hidden
	.modal
		overflow-x: hidden
		overflow-y: auto

.modal
	position: fixed
	top: 0
	left: 0
	z-index: 6000
	display: none
	width: 100%
	height: 100%
	overflow: hidden
	outline: 0
.modal__message
	font-size: 18px
.modal-content__wrapper-button
	text-align: center
	.button
		min-width: 200px

.modal__close
	position: absolute
	top: 0px
	right: 20px
	cursor: pointer
	z-index: 60
	&::before
		content: ''
		position: absolute
		height: calc( 100% + 35px)
		top: 0px
		left: -20px
		width: 1px
		background: var(--color-oro2)
		@media only screen and (max-width: $xs)
			height: calc( 100% + 18px)
	h6
		color: var(--color-oro2) !important
		text-transform: uppercase
		position: relative
		top: 20px
		@media only screen and (max-width: $xs)
			font-size: 14px
			top: 12px

.modal-dialog
	position: relative
	width: auto
	margin: 0
	pointer-events: none
.modal-dialog_container
	max-width: 980px !important

.modal
	&.fade .modal-dialog
		transition: -webkit-transform 0.3s ease-out
		transition: transform 0.3s ease-out
		transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out
		-webkit-transform: translate(0, -50px)
		transform: translate(0, -50px)
	&.show .modal-dialog
		-webkit-transform: none
		transform: none

@media (prefers-reduced-motion: reduce)
	.modal.fade .modal-dialog
		transition: none

.modal-dialog-scrollable
	display: -ms-flexbox
	display: flex
	max-height: calc(100% - 1rem)
	.modal-content
		max-height: calc(100vh - 1rem)
		overflow: hidden
	.modal-header, .modal-footer
		-ms-flex-negative: 0
		flex-shrink: 0
	.modal-body
		overflow-y: auto

.modal-dialog-centered
	display: -ms-flexbox
	display: flex
	-ms-flex-align: center
	align-items: center
	min-height: calc(100% - 1rem)
	&::before
		display: block
		height: calc(100vh - 1rem)
		content: ""
	&.modal-dialog-scrollable
		-ms-flex-direction: column
		flex-direction: column
		-ms-flex-pack: center
		justify-content: center
		height: 100%
		.modal-content
			max-height: none
		&::before
			content: none

.modal-content
	position: relative
	display: -ms-flexbox
	display: flex
	-ms-flex-direction: column
	flex-direction: column
	width: 100%
	pointer-events: auto
	background-color: #141414
	background-clip: padding-box
	outline: 0
	padding: 0px
	min-height: 100vh

.modal-backdrop
	position: fixed
	top: 0
	left: 0
	z-index: 1040
	width: 100vw
	height: 100vh
	background-color: #000
	&.fade
		opacity: 0
	&.show
		opacity: 0.7

.modal-header
	display: flex
	height: 80px
	position: absolute
	border-bottom: 1px solid var(--color-oro2)
	width: 100%
	z-index: 999
	@media only screen and (max-width: $xs)
		height: 60px

.modal-title
	margin-bottom: 0
	line-height: 1.5

.modal-body
	position: relative


.modal-footer
	display: -ms-flexbox
	display: flex
	-ms-flex-align: center
	align-items: center
	-ms-flex-pack: end
	justify-content: flex-end
	padding: 1rem
	border-top: 1px solid #dee2e6
	border-bottom-right-radius: 0.3rem
	border-bottom-left-radius: 0.3rem
	>
		\:not(:first-child)
			margin-left: .25rem
		\:not(:last-child)
			margin-right: .25rem

.modal-scrollbar-measure
	position: absolute
	top: -9999px
	width: 50px
	height: 50px
	overflow: scroll

@media (min-width: 576px)
	.modal-dialog
		max-width: 100%
		margin: 0px
	.modal-dialog-scrollable
		max-height: calc(100% - 3.5rem)
		.modal-content
			max-height: calc(100vh - 3.5rem)
	.modal-dialog-centered
		min-height: calc(100% - 3.5rem)
		&::before
			height: calc(100vh - 3.5rem)
	.modal-sm
		max-width: 300px

@media (min-width: 992px)
	.modal-lg, .modal-xl
		max-width: 800px
	.modal__message
		font-size: 24px

@media (min-width: 1200px)
	.modal-xl
		max-width: 1140px



.modal-nosotros
	.modal-body
		min-height: 100vh
		.of-cover
				position: absolute
				width: 50% !important
				padding: 0px !important
				@media only screen and (max-width: $sm)
					width: 100% !important
					position: relative
					height: 600px !important
				@media only screen and (max-width: $xs)	
					height: 600px !important
				&::before
					content: ''
					position: absolute
					right: 0
					height: 100%
					width: 200px
					background: transparent linear-gradient(270deg, #141414 0%, #141414 12%, rgba(20, 20, 20, 0.7843137255) 25%, rgba(20, 20, 20, 0.5450980392) 40%, rgba(20, 20, 20, 0.2901960784) 63%, rgba(20, 20, 20, 0.1019607843) 85%, rgba(20, 20, 20, 0) 100%) 0% 0% no-repeat padding-box
		.row
			min-height: 100vh
			.content
				padding: 0px 100px
				@media only screen and (max-width: $md)
					padding: 0px 30px
					

				> div 
					padding-top: 160px
					padding-bottom: 100px
					@media only screen and (max-width: $sm)
						padding-top: 120px
						padding-bottom: 60px

				img
					max-height: 45px
					@media only screen and (max-width: $md)
						max-height: 40px
					@media only screen and (max-width: $xs)
						max-height: 38px	
				h6
					color: #fff
					text-transform: uppercase
					margin-top: 20px
					margin-bottom: 50px	
					font-weight: bold
					letter-spacing: 2px
				p
					max-width: 600px	
					//padding-left: 60px
					color: #fff
					font-size: 16px
					@media only screen and (max-width: $lg)
						padding-left: 0px
					@media only screen and (max-width: $xs)
						font-size: 15px	
				a 
					//margin-left: 60px	
					margin-top: 20px
					@media only screen and (max-width: $lg)
						margin-left: 0px
					.button__label
						font-size: 13px
						color: #fff !important

					
