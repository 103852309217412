.section__headline.style_1
	width: 100%
	background: var(--color-oro2) !important

.section__headline.style_2
	width: 100%
	background: var(--color-oro) !important	

.scroll-down
	position: absolute
	bottom: 30px
	z-index: 99
	@media only screen and (max-width: $xs)	
		bottom: 20px
	.c-scrolldown
		height: 90px
		left: 0
		margin: 0 auto
		overflow: hidden
		position: relative
		right: 0
		top: 0
		width: 1px
		@media only screen and (max-width: $xs)
			height: 60px
	.c-line
		animation: scrolldown 3s cubic-bezier(.76, 0, .3, 1) infinite forwards
		background: linear-gradient(180deg, #fff 50%, hsla(0, 0%, 100%, 0) 0)
		background-position: 0 -90px
		background-size: 100% 200%
		display: block
		height: 100%
		width: 100%
		@media only screen and (max-width: $xs)
			background-position: 0 -60px

	.text__scroll
		font-size: 13px
		letter-spacing: 2px
		margin-top: 14px
		text-transform: uppercase


@keyframes scrolldown
	0%
		background-position: 0 -90px

	75%
		background-position: 0 0

	to
		background-position: 0 90px

@media only screen and (max-width: $xs)	
	@keyframes scrolldown
		0%
			background-position: 0 -60px

		75%
			background-position: 0 0

		to
			background-position: 0 60px


.play_ico
	width: 100px
	display: flex
	justify-content: center
	img
		width: 85px
		transform: scale(1)
		transition: all 1s ease	
		@media only screen and (max-width: $xl)
			width: 70px
		&:hover
			transform: scale(1.1)
			transition: all 1s ease	
			

.box-degradado-right
		width: 200px
		height: 100%
		position: absolute
		background: transparent linear-gradient(270deg, #141414 0%, #141414 12%, #141414C8 25%, #1414148B 40%, #1414144A 63%, #1414141A 85%, #14141400 100%) 0% 0% no-repeat padding-box
		opacity: 1
		right: 0	


.box-degradado-left
		width: 200px
		height: 100%
		position: absolute
		left: 0
		background: transparent linear-gradient(90deg, #141414 0%, #141414 12%, #141414C8 25%, #1414148B 40%, #1414144A 63%, #1414141A 85%, #14141400 100%) 0% 0% no-repeat padding-box
		opacity: 1
		right: 0		



.box-degradado-top
		width: 100%
		height: 200px
		position: absolute
		background: transparent linear-gradient(180deg, #141414 0%, #141414 12%, #141414C8 25%, #1414148B 40%, #1414144A 63%, #1414141A 85%, #14141400 100%) 0% 0% no-repeat padding-box
		opacity: 1	


.box-degradado-top-2
	width: 100%
	height: 200px
	position: absolute
	background: transparent linear-gradient(180deg, #1E1E1E 0%, #1E1E1E 14%, rgba(30, 30, 30, 0.7843137255) 38%, rgba(30, 30, 30, 0.2901960784) 60%, rgba(30, 30, 30, 0.1019607843) 82%, rgba(30, 30, 30, 0) 100%) 0% 0% no-repeat padding-box
	opacity: 1

.box-degradado-bottom
		width: 100%
		height: 200px
		position: absolute
		background: transparent linear-gradient(0deg, #141414 0%, #141414 12%, #141414C8 25%, #1414148B 40%, #1414144A 63%, #1414141A 85%, #14141400 100%) 0% 0% no-repeat padding-box
		opacity: 1
		bottom: 0	


.box-absolute
	position: absolute
	top: 0px
	width: 100%
	height: 100%

.center-fix
	margin: auto !important
	display: block !important
	

h2.italic.style_1
	line-height: 1
	span
		position: relative
		top: -12px
		@include fluid('font-size', 50, 60)

.line
	font-size: 15px
	span
		width: 16px
		height: 1px
		background: var(--paragraph-color-dark)
		display: inline-block
		position: relative
		top: -5px
		margin-right: 8px


#bg-video
	-o-object-fit: cover
	object-fit: cover
	width: 100vw
	height: 100vh
	left: 0			


.fix-flex
	@media only screen and (max-width: $sm)	
		flex-direction: column
		

.download
	img
		max-width: 20px	
		margin-right: 8px
		position: relative
		top: 0px