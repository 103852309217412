.footer
	color: $color-gray-3
	z-index: 100

	a
		font-size: 13px
		line-height: 0.3em !important
		color: $color-gray-3
		
	.social
		a:before
			color: #fff
			font-size: 20px
			@media only screen and (max-width: $md)
				font-size: 18px
			@media only screen and (max-width: $xs)
				font-size: 16px	

	.widgettitle
		display: block
		font-size: 13px
		font-weight: 600
		line-height: 1
		margin-top: 0
		margin-bottom: 1em
		&:after
			display: none
.footer__divider
	display: block
	position: absolute
	top: 0
	left: calc(var(--bs-gutter-x) / 2)
	right: calc(var(--bs-gutter-x) / 2)
	height: 1px
	padding-left: 0
	padding-right: 0
	width: auto
	background-color: var(--color-oro2)

.logo_footer
	@media only screen and (max-width: $md)
		width: 800px !important
